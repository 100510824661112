<template>
  <div>
    <navbar :activeNav="activeNav" />
    <section id="home" class="section gray-bg">
      <section-home></section-home>
    </section>

    <section id="solutions" class="section white-bg">
      <section-solutions></section-solutions>
    </section>

    <section id="customers" class="section gray-bg">
      <section-customers></section-customers>
    </section>

    <section id="aboutUs" class="section white-bg">
      <section-aboutUs></section-aboutUs>
    </section>

    <section id="contactUs" class="section gray-bg">
      <section-contactUs></section-contactUs>
    </section>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'

import SectionHome from './sections/Home'
import SectionSolutions from './sections/Solutions'
import SectionCustomers from './sections/Customers'
import SectionAboutUs from './sections/AboutUs'
import SectionContactUs from './sections/ContactUs'

export default {
  name: 'Home',
  components: {
    Navbar,
    SectionHome,
    SectionSolutions,
    SectionCustomers,
    SectionAboutUs,
    SectionContactUs
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroy() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleScroll(event) {
      const children = this.$el.querySelectorAll('section.section')

      children.forEach(element => {
        const top = element.offsetTop - 52
        const bottom = top + element.scrollHeight
        if (window.scrollY >= top && window.scrollY < bottom)
          this.activeNav = element.id
      })
    }
  },
  data() {
    return {
      activeNav: 'home'
    }
  }
}
</script>
