<template>
  <vue-circle
    :progress="95"
    :size="250"
    :reverse="false"
    line-cap="round"
    :fill="fill"
    empty-fill="rgba(0, 0, 0, 0)"
    :animation-start-value="0.0"
    :start-angle="70"
    insert-mode="append"
    :thickness="10"
    :show-percent="false"
  >
    <div class="circle-title">{{ title }}</div>
    <div class="circle-description" v-html="description"></div>
  </vue-circle>
</template>
<script>
import VueCircle from 'vue2-circle-progress'

export default {
  name: 'CircleProgress',
  props: {
    title: String,
    description: String
  },
  components: {
    VueCircle
  },
  data() {
    return {
      fill: { gradient: ['#55BA90', '#C7DFD8'], gradientAngle: 30 }
    }
  }
}
</script>

<style scoped lang="scss">
.circle-title {
  font-size: 4rem;
  font-weight: 900;
  color: $primary;
}
.circle-description {
  font-weight: 700;
}
</style>
