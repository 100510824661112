<template>
  <div class="container">
    <div class="pl-30 pr-30 mt-30">
      <div class="columns">
        <div class="column is-6">
          <img src="@/assets/accentix/logo-color-black-text.png" width="400" />

          <div class="header mt-50" v-html="$t('home.title')"></div>
          <div class="description mt-50" v-html="$t('home.description')"></div>
          <div class="mt-30">
            <button class="btn-primary" v-scroll-to="`#contactUs`">
              <span>{{ $t('button.talkToSales') }}</span>
            </button>
          </div>
        </div>
        <div class="column is-6 has-text-centered">
          <img src="@/assets/accentix/amity-voicebot.webp" width="400" />
        </div>
      </div>
      <div class="columns mt-30">
        <div class="column has-text-centered">
          <iframe
            class="youtube"
            src="https://www.youtube.com/embed/wnSAeXuItKw?controls=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="columns is-multiline is-centered mt-30">
        <div
          class="column has-text-centered is-one-third-tablet is-one-quarter-desktop is-one-quarter-widescreen ml-30 mr-30"
          v-for="(item, index) in $t('home.progress')"
          :key="index"
        >
          <circle-progress
            :title="item.title"
            :description="item.description"
          ></circle-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleProgress from '@/components/CircleProgress'

export default {
  name: 'Home',
  components: {
    CircleProgress
  }
}
</script>
