<template>
  <div id="app">
    <router-view />
    <Footer />
    <transition name="fade">
      <a href="#" class="back-to-top" v-if="isShowToTop" v-scroll-to="'#navbar'"
        ><b-icon pack="fas" icon="chevron-up" size="is-small"></b-icon
      ></a>
    </transition>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import '@/scss/variables.scss'

export default {
  name: 'App',
  components: {
    Footer
  },
  data() {
    return {
      isShowToTop: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroy() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleScroll(e) {
      this.isShowToTop =
        document.body.scrollTop > 50 || document.documentElement.scrollTop > 50
    },
    backToTop() {
      document.body.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss">
@import '~bulma';
@import '~buefy/src/scss/buefy';
@import '@/scss/default.scss';
@import '@/scss/custom.scss';

@import '~aos/dist/aos.css';
</style>
