<template>
  <b-navbar id="navbar" v-bind:fixed-top="true">
    <template slot="brand">
      <b-navbar-item v-scroll-to="`#home`">
        <img
          src="@/assets/accentix/logo-color-black-text.png"
          alt="We are the leader in virtual agents"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item
        v-for="(menu, index) of menus"
        :active="activeNav === menu"
        v-scroll-to="`#${menu}`"
        :key="index"
      >
        {{ $t(`navbar.${menu}`) }}
      </b-navbar-item>
      <!-- <b-navbar-dropdown label="Info">
        <b-navbar-item href="#solution">
          About
        </b-navbar-item>
        <b-navbar-item href="#our-customer">
          Contact
        </b-navbar-item>
      </b-navbar-dropdown> -->
    </template>

    <template slot="end">
      <button class="btn-primary" v-scroll-to="`#contactUs`">
        <span>{{ $t(`button.talkToSales`) }}</span>
      </button>
      <!-- <span
          class="switch-lang"
          @click="$i18n.locale = 'en'"
          :active="$i18n.locale == 'en'"
          >EN</span
        >
        <span class="gray">/</span>
        <span
          class="switch-lang"
          @click="$i18n.locale = 'th'"
          :active="$i18n.locale == 'th'"
          >TH</span
        > -->
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navber',
  props: {
    activeNav: {
      type: String,
      default: 'home'
    }
  },
  data() {
    return {
      menus: ['solutions', 'customers', 'aboutUs', 'contactUs']
    }
  }
}
</script>
