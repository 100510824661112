<template>
  <footer id="footer">
    <div class="footer-copyright text-center">
      <p class="text">
        © Amity Accentix Company Limited. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
