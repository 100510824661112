<template>
  <div class="container">
    <div class="pl-30 pr-30 mt-30 mb-30">
      <div class="columns">
        <div class="column is-6">
          <div class="header" v-html="$t('contactUs.title')"></div>
          <div
            class="description mt-10"
            v-html="$t('contactUs.description')"
          ></div>
          <div class="flex mt-30">
            <div class="contact-info-icon">
              <b-icon pack="fas" icon="envelope"></b-icon>
            </div>
            <div class="contact-info-content ml-20">
              <p class="text">
                <a href="mailto:info@accentix.ai">info@accentix.ai</a>
              </p>
            </div>
          </div>
          <div class="flex mt-20">
            <div class="contact-info-icon">
              <b-icon pack="fas" icon="phone"></b-icon>
            </div>
            <div class="contact-info-content ml-20">
              <p class="text">
                <a href="tel:+66953659287">+66 95 365 9287</a>
              </p>
            </div>
          </div>
          <div class="flex mt-20">
            <div class="contact-info-icon">
              <b-icon pack="fas" icon="map-marker-alt"></b-icon>
            </div>
            <div class="contact-info-content ml-20">
              <p class="text" v-html="$t('contactUs.address')"></p>
              <br />
              <a :href="googleDirection" target="_blank">
                <p class="text" v-html="$t('contactUs.direction')"></p>
              </a>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <form id="sendEmail" @submit="contactForm" method="post" class="box">
            <div class="columns">
              <!-- Name -->
              <div class="column">
                <b-field
                  :label="$t('contactUs.form.name.label')"
                  :type="errFormName !== '' ? 'is-danger' : ''"
                  :message="errFormName"
                >
                  <b-input
                    type="text"
                    v-model="name"
                    name="name"
                    @focus="clearError('errFormName')"
                  ></b-input>
                </b-field>
              </div>

              <!-- phone -->
              <div class="column">
                <b-field
                  :label="$t('contactUs.form.phone.label')"
                  :type="errFormPhone !== '' ? 'is-danger' : ''"
                  :message="errFormPhone"
                >
                  <b-input
                    type="text"
                    v-model="phone"
                    name="phone"
                    @focus="clearError('errFormPhone')"
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <!-- email -->
              <div class="column">
                <b-field
                  :label="$t('contactUs.form.email.label')"
                  :type="errFormEmail !== '' ? 'is-danger' : ''"
                  :message="errFormEmail"
                >
                  <b-input
                    type="text"
                    v-model="email"
                    name="email"
                    @focus="clearError('errFormEmail')"
                  ></b-input>
                </b-field>
              </div>

              <!-- company -->
              <div class="column">
                <b-field
                  :label="$t('contactUs.form.company.label')"
                  :type="errFormCompany !== '' ? 'is-danger' : ''"
                  :message="errFormCompany"
                >
                  <b-input
                    type="text"
                    v-model="company"
                    name="company"
                    @focus="clearError('errFormCompany')"
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field
                  :label="$t('contactUs.form.message.label')"
                  :type="errFormMessage != '' ? 'is-danger' : ''"
                  :message="errFormMessage"
                >
                  <b-input
                    type="textarea"
                    v-model="message"
                    name="message"
                    @focus="clearError('errFormMessage')"
                    maxlength="2000"
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column pt-0">
                <button type="submit" class="btn-primary submit">
                  <span>{{ $t('contactUs.form.button.submit') }}</span>
                </button>

                <div class="mt-20 text-small">
                  By submitting this form you allow the processing and storage
                  of the provided information for contact purposes and agree th
                  the
                  <a
                    href="https://www.amitysolutions.com/legal/privacy-policy-website"
                    target="_blank"
                    >Privacy Policy</a
                  >.
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  data() {
    return {
      googleDirection:
        'https://www.google.com/maps/dir//%E0%B8%97%E0%B8%A3%E0%B8%B9+%E0%B8%94%E0%B8%B4%E0%B8%88%E0%B8%B4%E0%B8%97%E0%B8%B1%E0%B8%A5+%E0%B8%9E%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%84+101+%E0%B8%96%E0%B8%99%E0%B8%99+%E0%B8%AA%E0%B8%B8%E0%B8%82%E0%B8%B8%E0%B8%A1%E0%B8%A7%E0%B8%B4%E0%B8%97+101%2F1+%E0%B8%9A%E0%B8%B2%E0%B8%87%E0%B8%88%E0%B8%B2%E0%B8%81+%E0%B9%80%E0%B8%82%E0%B8%95%E0%B8%9E%E0%B8%A3%E0%B8%B0%E0%B9%82%E0%B8%82%E0%B8%99%E0%B8%87+%E0%B8%81%E0%B8%A3%E0%B8%B8%E0%B8%87%E0%B9%80%E0%B8%97%E0%B8%9E%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%99%E0%B8%84%E0%B8%A3+10260/@13.6852229,100.6110681,16.83z/data=!4m17!1m7!3m6!1s0x30e29ed269181bb1:0x60c3178ba983c76!2z4LiX4Lij4Li5IOC4lOC4tOC4iOC4tOC4l-C4seC4pSDguJ7guLLguKPguYzguIQ!8m2!3d13.6851839!4d100.6113255!16s%2Fg%2F11h3cbv1yq!4m8!1m0!1m5!1m1!1s0x30e29ed269181bb1:0x60c3178ba983c76!2m2!1d100.6113255!2d13.6851839!3e3?entry=ttu&g_ep=EgoyMDI0MTIwNC4wIKXMDSoASAFQAw%3D%3D',
      name: '',
      errFormName: '',
      phone: '',
      errFormPhone: '',
      email: '',
      errFormEmail: '',
      company: '',
      errFormCompany: '',
      message: '',
      errFormMessage: ''
    }
  },
  methods: {
    async contactForm(e) {
      e.preventDefault()

      if (!this.name) {
        this.errFormName = this.$t('contactUs.form.name.isNull')
        return
      }
      if (!this.phone) {
        this.errFormPhone = this.$t('contactUs.form.phone.isNull')
        return
      }
      if (!this.validPhone(this.phone)) {
        this.errFormPhone = this.$t('contactUs.form.phone.invalid')
        return
      }
      if (!this.email) {
        this.errFormEmail = this.$t('contactUs.form.email.isNull')
        return
      }
      if (!this.validEmail(this.email)) {
        this.errFormEmail = this.$t('contactUs.form.email.invalid')
        return
      }
      if (!this.company) {
        this.errFormCompany = this.$t('contactUs.form.company.isNull')
        return
      }
      if (!this.message) {
        this.errFormMessage = this.$t('contactUs.form.message.isNull')
        return
      }

      const loadingComponent = this.$buefy.loading.open()

      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      const data = {
        action: 'contactForm',
        token: token,
        name: this.name,
        phone: this.phone,
        email: this.email,
        company: this.company,
        message: this.message
      }

      this.$axios
        .post(`/mailer/send`, data)
        .then(response => {
          this.name = ''
          this.phone = ''
          this.email = ''
          this.company = ''
          this.message = ''

          this.$buefy.dialog.alert({
            type: 'is-success',
            message:
              'Thank you for your interest in Accentix.<br/>We will reach back to you shortly.',
            confirmText: 'OK'
          })
        })
        .catch(error => {
          this.$buefy.dialog.alert({
            type: 'is-danger',
            message: error,
            confirmText: 'Close',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
        .finally(() => loadingComponent.close())
    },
    clearError(error) {
      this[error] = ''
    },
    validPhone(phone) {
      const re = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i
      return re.test(phone)
    },
    validEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>
