<template>
  <div class="container">
    <div class="pl-30 pr-30 mt-30 mb-30">
      <div class="columns is-vcentered">
        <div class="column is-6">
          <div class="header" v-html="$t('aboutUs.title')"></div>
          <div
            class="description mt-30"
            v-html="$t('aboutUs.description')"
          ></div>
          <div class="mt-30">
            <button class="btn-primary visit" @click="goToAmitySolutions()">
              <span>{{ $t('button.visitAmitySolutions') }}</span>
            </button>
          </div>
        </div>
        <div class="column is-6 has-text-centered">
          <img src="@/assets/accentix/voicebot-hello.webp" width="400" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  methods: {
    goToAmitySolutions() {
      window.open('https://amitysolutions.com', '_blank')
    }
  }
}
</script>
