<template>
  <div class="container">
    <div class="pl-30 pr-30 mt-30 mb-30">
      <div class="columns">
        <div class="column is-6 has-text-centered">
          <img src="@/assets/accentix/clients.png" width="500" />
        </div>
        <div class="column is-6">
          <div class="header mt-40" v-html="$t('customers.title')"></div>
          <div
            class="description mt-30"
            v-html="$t('customers.description')"
          ></div>
        </div>
      </div>
      <div
        v-for="(row, indexRow) in customers"
        :key="`row-${indexRow}`"
        class="columns is-multiline is-mobile is-centered"
      >
        <div
          v-for="(customer, index) in row"
          :key="index"
          class="column is-one-third-mobile"
          data-aos="fade-up"
          :data-aos-delay="index * 100"
        >
          <div class="customer-circle">
            <img :src="customer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logoTrue from '@/assets/customers/logo-true.png'
import logoKrungsri from '@/assets/customers/logo-krungsri.png'
import logoKbank from '@/assets/customers/logo-kbank.png'
import logoScb from '@/assets/customers/logo-scb.png'
import logoGsb from '@/assets/customers/logo-gsb.webp'
import logoLh from '@/assets/customers/logo-lh.jpeg'
import logoAia from '@/assets/customers/logo-aia.png'
import logoBangkokInsurance from '@/assets/customers/logo-bangkok-insurance.png'
import logoAsk from '@/assets/customers/logo-ask.png'
import logoLineBk from '@/assets/customers/logo-line-bk.png'
import logoHomepro from '@/assets/customers/logo-homepro.png'
import logoScgHome from '@/assets/customers/logo-scg-home.jpg'
import logoCpf from '@/assets/customers/logo-cpf.png'
import logoSirirajPiya from '@/assets/customers/logo-siriraj-piya.png'
import logoSocialSecurity from '@/assets/customers/logo-social-security.jpg'
import logo2c2p from '@/assets/customers/logo-2c2p.png'
import logoMg from '@/assets/customers/logo-mg.jpg'
import logoThaiSmile from '@/assets/customers/logo-thai-smile.png'

import logoSf from '@/assets/customers/logo-sf.jpeg'

export default {
  name: 'Customers',
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  created() {
    this.onResize()
  },
  data() {
    return {
      customers: [],
      customerList: [
        logoTrue,
        logoKrungsri,
        logoKbank,
        logoScb,
        logoGsb,
        logoLh,
        logoAia,
        logoBangkokInsurance,
        logoAsk,
        logoLineBk,
        logoHomepro,
        logoScgHome,
        logoCpf,
        logoSirirajPiya,
        logoSocialSecurity,
        logo2c2p,
        logoMg,
        logoThaiSmile,
        logoSf
      ]
    }
  },
  methods: {
    onResize() {
      if (this.isDesktop())
        this.customers = [
          [...this.customerList.slice(0, 6)],
          [...this.customerList.slice(6, 13)],
          [...this.customerList.slice(13, 19)]
        ]
      else this.customers = [[...this.customerList]]
    },
    isDesktop() {
      if (screen.width > 1024) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
