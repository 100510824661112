<template>
  <div class="container">
    <div class="pl-30 pr-30 mt-30 mb-30">
      <div class="columns">
        <div class="column is-6 has-text-centered">
          <img src="@/assets/accentix/response-flow.webp" width="500" />
        </div>
        <div class="column is-6">
          <div class="header mt-40" v-html="$t('solutions.title')"></div>
          <div
            class="description mt-30"
            v-html="$t('solutions.description')"
          ></div>
        </div>
      </div>
      <div class="columns mt-30">
        <div class="column is-4 has-text-centered">
          <div class="box-icon">
            <b-icon pack="fa" icon="dollar-sign"></b-icon>
          </div>
          <div
            class="mt-20 has-text-weight-bold"
            v-html="$t('solutions.paymentReminder.title')"
          ></div>
          <div
            class="mt-20"
            v-html="$t('solutions.paymentReminder.description')"
          ></div>
        </div>
        <div class="column is-4 has-text-centered">
          <div class="box-icon">
            <b-icon pack="fa" icon="calendar-check"></b-icon>
          </div>
          <div
            class="mt-20 has-text-weight-bold"
            v-html="$t('solutions.appointmentConfirmation.title')"
          ></div>
          <div
            class="mt-20"
            v-html="$t('solutions.appointmentConfirmation.description')"
          ></div>
        </div>
        <div class="column is-4 has-text-centered">
          <div class="box-icon">
            <b-icon pack="fa" icon="phone-volume"></b-icon>
          </div>
          <div
            class="mt-20 has-text-weight-bold"
            v-html="$t('solutions.welcomeCalls.title')"
          ></div>
          <div
            class="mt-20"
            v-html="$t('solutions.welcomeCalls.description')"
          ></div>
        </div>
      </div>
      <div class="columns mt-50">
        <div class="column has-text-centered">
          <iframe
            class="youtube"
            src="https://www.youtube.com/embed/3rNxwH_WSTw?controls=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="columns mt-50 is-vcentered">
        <div class="column is-6">
          <div class="header" v-html="$t('solutions.interaction.title')"></div>
          <div
            class="description mt-30"
            v-html="$t('solutions.interaction.description')"
          ></div>
        </div>
        <div class="column is-6 has-text-centered">
          <img src="@/assets/accentix/voicebot-conversation.webp" width="400" />
        </div>
      </div>
      <div class="columns mt-30 is-multiline is-centered">
        <div
          v-for="(solution, index) in solutions"
          v-bind:key="index"
          class="column has-text-centered"
          data-aos="fade-up"
          :data-aos-delay="index * 100"
          @click="showSolution(index)"
        >
          <div class="flip-container">
            <div class="front">
              <div class="card-content">
                <div class="card-icon">
                  <img :src="solution.icon" />
                  <!-- <b-icon pack="fas" :icon="item.icon"></b-icon> -->
                </div>
                <div class="card-title">
                  {{ $t(solution.title) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
      <div class="card card-modal">
        <div class="card-image">
          <div class="card-icon">
            <img :src="solutions[solutionSelected].icon" />
            <!-- <b-icon pack="fas" :icon="item.icon"></b-icon> -->
          </div>
          <div class="card-title">
            {{ $t(solutions[solutionSelected].title) }}
          </div>
        </div>
        <div class="card-content">
          <div
            class="content"
            v-html="$t(solutions[solutionSelected].desc)"
          ></div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import solBanking from '@/assets/solutions/banking.png'
import solTelecom from '@/assets/solutions/telecom.png'
import solAirline from '@/assets/solutions/airline.png'
import solHotel from '@/assets/solutions/hotel.png'
import solInsurance from '@/assets/solutions/insurance.png'
import solHospital from '@/assets/solutions/hospital.png'
import solEntertainment from '@/assets/solutions/entertainment.png'
import solAutomation from '@/assets/solutions/automation.png'

export default {
  name: 'Solutions',
  data() {
    return {
      isModalActive: false,
      solutionSelected: 0,
      solutions: [
        {
          title: 'solutions.banking.title',
          icon: solBanking,
          desc: 'solutions.banking.text'
        },
        {
          title: 'solutions.telecom.title',
          icon: solTelecom,
          desc: 'solutions.telecom.text'
        },
        {
          title: 'solutions.airline.title',
          icon: solAirline,
          desc: 'solutions.airline.text'
        },
        {
          title: 'solutions.hotel.title',
          icon: solHotel,
          desc: 'solutions.hotel.text'
        },
        {
          title: 'solutions.insurance.title',
          icon: solInsurance,
          desc: 'solutions.insurance.text'
        },
        {
          title: 'solutions.hospital.title',
          icon: solHospital,
          desc: 'solutions.hospital.text'
        },
        {
          title: 'solutions.entertainment.title',
          icon: solEntertainment,
          desc: 'solutions.entertainment.text'
        },
        {
          title: 'solutions.automation.title',
          icon: solAutomation,
          desc: 'solutions.automation.text'
        }
      ]
    }
  },
  methods: {
    showSolution(index) {
      this.solutionSelected = index
      this.isModalActive = true
    }
  }
}
</script>

<style scoped lang="scss">
.box-icon {
  margin: auto;
  width: 60px;
  height: 60px;
  background-color: $primary;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    font-size: 20px;
    color: $white;
  }
}
</style>
